import { AppTheme } from '@backstage/core-plugin-api';
import {
  UnifiedThemeProvider,
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';
import LightIcon from '@material-ui/icons/WbSunny';
import React from 'react';

// import { darkTheme, lightTheme } from './theme/basicThemes';
// import { UnifiedThemeProvider } from '@backstage/theme';



// Rithum Company Colors
// #742BA1 Grape
// #F95E46 Coral
// #FFFFFF White
// #141414 Barely Black

const darkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.dark,
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#742BA1', '#F95E46'], shape: shapes.wave2 }),
    documentation: genPageTheme({ colors: ['#742BA1', '#F95E46'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#742BA1', '#F95E46'], shape: shapes.wave2 }),
  }
});

const lightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.light,
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#742BA1', '#F95E46'], shape: shapes.wave }),
    documentation: genPageTheme({ colors: ['#742BA1', '#F95E46'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#742BA1', '#F95E46'], shape: shapes.wave2 }),
  },
});

export const customThemes: (Partial<AppTheme> & Omit<AppTheme, 'theme'>)[] = [
  {
    id: 'darkTheme',
    title: 'Dark Theme',
    variant: 'dark',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={darkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </UnifiedThemeProvider>
    ),
  },
  {
    id: 'lightTheme',
    title: 'Light Theme',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={lightTheme}>
        <CssBaseline>{children}</CssBaseline>
      </UnifiedThemeProvider>
    ),
  },
];